import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
  userId: '',
  username: '',
  avatar: '',
  nombre: '',
  tokenString: '',

  empresas: [],
  empresaId: '',
  empresaNombre: '',

  authValid: false
};

const mutations = {
  'LOGIN'(state, params) {
    state.userId = params.usuario.id;
    state.username = params.usuario.username;
    state.nombre = params.usuario.username;

    state.empresas = params.usuario.empresas;
    state.empresaId = '';
    state.empresaNombre = '';

    if (params.usuarioEmpresa) {
      state.empresaId = params.usuarioEmpresa.id;
      state.empresaNombre = params.usuarioEmpresa.nombre;
    }
    
    state.tokenString = params.tokenString;
  },
  'INICIALIZAR_EMPRESA'(state) {
    state.empresaId = '';
    state.empresaNombre = '';
  },
  'LOGIN_EMPRESA'(state, params) {
    state.empresaId = params.usuarioEmpresa.id;
    state.empresaNombre = params.usuarioEmpresa.nombre;
    
    state.tokenString = params.tokenString;
  },
  'LOGOUT'(state) {
    state.userId = '';
    state.username = '';
    state.nombre = '';

    state.empresas = [];
    state.empresaId = '';
    state.empresaNombre = '';

    state.tokenString = '';
  },
  'PERFIL'(params) {
    state.nombre = params.nombre;
    // state.avatar = params.avatar;
  },
};

const actions = {
  tryLogin: ( { commit, state } ) => {
    return new Promise((resolve, reject) => {
      /* Recuperamos los datos del localStorage */  
      let username = localStorage.getItem('userName');
      let tokenString = localStorage.getItem('token');
      let expirationDate = new Date(localStorage.getItem('expirationDate', expirationDate));
      let currentDate = new Date();

      /* Validamos datos del usuario y la fecha de expiración */
      if (username && tokenString
          && expirationDate.getTime() > currentDate.getTime()) {
        
        let credenciales = {
          username,
          tokenString
        }
        apiAxios.post('auth/autologin', credenciales) 
          .then(res => {
            commit('LOGIN', res.data);
            const now = new Date();
            const expirationDate = new Date(now.getTime() + 28800000);
            localStorage.setItem('userName', state.username);
            localStorage.setItem('token', state.tokenString);
            localStorage.setItem('expirationDate', expirationDate);

            resolve();
          })
          .catch(error => reject(helpers.methods.getErrorMessage(error)));
      } else {
        reject();
      }
    })
  },
  login: ( { commit, state  }, params) => {
        return new Promise((resolve, reject) => {
          commit('LOGOUT');
          
          apiAxios.post('auth/login', params) 
            .then(res => {
              commit('LOGIN', res.data);

              const now = new Date();
              const expirationDate = new Date(now.getTime() + 28800000);
              localStorage.setItem('userName', state.username);
              localStorage.setItem('token', state.tokenString);
              localStorage.setItem('expirationDate', expirationDate);

              resolve();
            })
            .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    loginEmpresa: ( { commit, state  }, params) => {
      return new Promise((resolve, reject) => {
        commit('INICIALIZAR_EMPRESA');

        let credenciales = {
          username: state.username,
          tokenString: state.tokenString,
          empresaId: params.empresaId
        }
        apiAxios.post('auth/empresa', credenciales) 
          .then(res => {
            commit('LOGIN_EMPRESA', res.data);
            
            /* Actualizamos los datos del token */
            const now = new Date();
            const expirationDate = new Date(now.getTime() + 28800000);
            localStorage.setItem('userName', state.username);
            localStorage.setItem('token', state.tokenString);
            localStorage.setItem('expirationDate', expirationDate);

            resolve();
          })
          .catch(error => reject(helpers.methods.getErrorMessage(error)));
    })
  },
  logout: ({commit}) => {
        commit('LOGOUT');

        localStorage.removeItem('userName');
        localStorage.removeItem('token');
        localStorage.removeItem('expirationDate');
    },
};

const getters = {
  loggedIn(state) {
    let loggedIn = false;

    if (state.userId) {
      if (state.userId != '') {
        var expirationDate = new Date(localStorage.getItem('expirationDate', expirationDate));
        var currentDate = new Date();
        
        if (expirationDate.getTime() > currentDate.getTime()) {
          loggedIn = true;
        }
      }
    }

    return loggedIn;
  },
  empresaSeleccionada(state) {
    if (state.empresaId) {
      if (state.empresaId != '') {
        return true;
      }
    }
    return false;
  },
  userId (state) {
    return state.userId;
  },
  username (state) {
    return state.username;
  },
  nombre (state) {
    return state.nombre;
  },
  avatar (state) {
    return state.avatar;
  },
  tokenString (state) {
    return state.tokenString;
  },
  empresas (state) {
    return state.empresas;
  },
  empresasList (state) {
    if (state.empresas) {
      if (state.empresas.length != 0) {
        return state.empresas.map(empresa => empresa.nombre.trim());
      }
    }
    return [];
  },
  empresaId (state) {
    return state.empresaId;
  },
  empresaNombre (state) {
    return state.empresaNombre;
  },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}