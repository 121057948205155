export const msjModal = {
    data () {
        return {
            modalMsjVisible: false,
            modalMensaje: '',
            modalOkAction: null,
            modalHiddenAction: null,
            modalVariant: 'light'
        }
    },
    methods: {
        showModalMsj(mensaje, okAction, hiddenAction, variant) {
            this.modalMensaje = mensaje;
            this.modalOkAction = okAction;
            this.modalHiddenAction = hiddenAction;
            this.modalVariant = variant;
            this.modalMsjVisible = true;
        },
        showErrorMsj(error) {
            let mensaje = 'Error';

            /* Verificamos si el parametro es un objeto (Error) o un string */
            if (error) {
                if (typeof(error) === 'object') {
                    if (error.hasOwnProperty('message')) {
                        mensaje = error.message;
                        if (typeof(error) === 'object') {
                            if (error.hasOwnProperty('message')) {
                                mensaje = error.message;
                            } else if (error.hasOwnProperty('Message')) {
                                mensaje = error.Message;
                            }
        
                            if (mensaje.trim() == "ModelStateInvalid") {
                                if (error.Data) {
                                    if (error.Data.validations) {
                                        mensaje = '';
                                        for (const validationItem in error.Data.validations) {
                                            let validation = error.Data.validations[validationItem];
                                            
                                            // mensaje += '\r\n' + validation.key.trim() + ':';
                                            if (validation.errors) {
                                                validation.errors.forEach(error => {
                                                    mensaje += '\r\n' + error.errorMessage.trim();
                                                });
                                            }
                                        } 
                                    }
                                }
                            }
                        }
                    } 
                }
                else if (typeof(error) === 'string') {
                    mensaje = error;
                }
            }

            this.modalMensaje = mensaje;
            this.modalOkAction = null;
            this.modalHiddenAction = null;
            this.modalVariant = 'danger';
            this.modalMsjVisible = true;
        },
        okAction(){
            if (this.modalOkAction) {
                this.modalOkAction();
            }
            this.modalMsjVisible = false;
        },
        hiddenAction(){
            if (this.modalHiddenAction) {
                this.modalHiddenAction();
            }
            this.modalMsjVisible = false;
        }
    }
}

export const confirmModal = {
    data () {
        return {
            confirmModalVisible: false,
            confirmMsj: '',
            confirmOkAction: null,
            confirmOkTitle: '',
            confirmCancelAction: null,
            confirmCancelTitle: '',
        }
    },
    methods: {
        showModalConfirm(mensaje, okAction, okTitle, cancelAction, cancelTitle) {
            this.confirmMsj = mensaje;
            this.confirmOkAction = okAction;
            this.confirmOkTitle = okTitle;
            this.confirmCancelAction = cancelAction;
            this.confirmCancelTitle = cancelTitle;
            this.confirmModalVisible = true;
        },
        confirmOk() {
            if (this.confirmOkAction) {
                this.confirmOkAction();
            }
            this.confirmModalVisible = false;
        },
        confirmCancel() {
            if (this.confirmCancelAction) {
                this.confirmCancelAction();
            } 
            this.confirmModalVisible = false;
        },
      
    }
}
