<template>
  <b-modal 
      title="Proprietas"
      header-bg-variant= "light" 
      hide-header-close
      v-model="isVisible"
      @hidden="confirmHidden">
      {{ mensajeDialog }}
    <div slot="modal-footer">
      <b-button size="sm" variant="primary" class="footer-button"
        @click.prevent="confirmOk">{{ okTitleDialog }}</b-button>
      <b-button size="sm" variant="danger" class="footer-button"
        @click.prevent="confirmCancel">{{ cancelTitleDialog }}</b-button>
    </div>      
  </b-modal>  
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    mensaje: {
      type: String,
      default: ''
    },
    okTitle: {
      type: String,
      default: ''
    },
    cancelTitle: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      resolved: false
    }
  },
  computed: {
    isVisible: {
      get: function () {
        return this.visible;
      },
      set: function (newValue) {}
    },    
    mensajeDialog: {
      get: function () {
        return this.mensaje;
      },
      set: function (newValue) {}
    },    
    okTitleDialog: {
      get: function () {
        return this.okTitle;
      },
      set: function (newValue) {}
    },    
    cancelTitleDialog: {
      get: function () {
        return this.cancelTitle;
      },
      set: function (newValue) {}
    },    
  },
  methods: {
    confirmOk() {
      this.resolved = true;
      this.$emit('confirmOk');
    },
    confirmCancel() {
      this.resolved = true;
      this.$emit('confirmCancel');
    },
    confirmHidden() {
      if (!(/true/i).test(this.resolved)) {
        this.$emit('confirmCancel');
      }
    }
  }
}
</script>

<style>
</style>
