import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
// import helpers from '../../mixins/helpers';

const state = {
	id: '',
	nombre: '',
};

const mutations = {
	updateField,

	'INICIALIZAR_TIPO_PROPIEDAD'(state, tipoPropiedadId) {
		state.id = tipoPropiedadId;
		state.nombre = '';
	},
	'SET_TIPO_PROPIEDAD'(state, tipoPropiedad) {
		state.nombre = tipoPropiedad.nombre.trim();
	},
};

const actions = {
	getTipoPropiedad: ({ commit }, tipoPropiedadId) => {
		return new Promise((resolve, reject) => {
			commit('INICIALIZAR_TIPO_PROPIEDAD', tipoPropiedadId);

			let getDataTipoPropiedad = false;

			if (tipoPropiedadId) {
				if (tipoPropiedadId != '') {
					getDataTipoPropiedad = true;
				}
			}

			if (getDataTipoPropiedad) {
				apiAxios.get('tipospropiedades/' + tipoPropiedadId)
					.then(res => {
						commit('SET_TIPO_PROPIEDAD', res.data)
						resolve();
					})
					.catch(error => reject(error));
					// .catch(error => reject(helpers.methods.getErrorMessage(error)));
			} else {
				resolve();
			}
		})
	},
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let tipoPropiedad = {
				nombre: state.nombre.trim(),
            }

            let url = 'tipospropiedades';
            if (state.id != '') {
                // bancoCuenta.rowVersion = state.rowVersion;
                url += '/' + state.id;
            }

            apiAxios.post(url, tipoPropiedad)
                .then(() => resolve())
				.catch( error => reject(error));
        })
    },
};

const getters = {
	getField,
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}