import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';
import moment from 'moment';

const state = {
	comentarios: [],

	/* Opciones de filtro y paginacion */
    paginaActual: 1,
    registrosPorPagina: 10,
    totalRegistros: 0,
    totalPaginas: 0,

    fechaDesde: moment(new Date()).add(-30, 'days').format('YYYY-MM-DD'),
    fechaHasta: moment(new Date()).format('YYYY-MM-DD'),
};

const mutations = {
	updateField,

	'INICIALIZAR_COMENTARIOS'(state) {
		state.comentarios = [];
	},
    'SET_PAGINATION'(state, params) {
        state.paginaActual = params.paginaActual;
        state.registrosPorPagina = params.registrosPorPagina;
        state.totalRegistros = params.totalRegistros;
        state.totalPaginas = params.totalPaginas;
    },
	'SET_COMENTARIOS'(state, comentarios) {
		state.comentarios = comentarios.map(comentario => ({
			...comentario,
			_rowVariant: moment.utc(comentario.fechaLeido).format('YYYY-MM-DD') == '1900-01-01' ? 'danger' : ''
		}))
	},
};

const actions = {
	getComentarios: ({ commit }) => {
		return new Promise((resolve, reject) => {
			let getComentarios = true;

			// if (state.propiedades) {
			//     if (state.propiedades.length != 0) {
			//         getPropiedades = true;
			//     }
			// }

			if (getComentarios) {
				let queryString = '?pageSize=' + state.registrosPorPagina
					+ '&pageNumber=' + state.paginaActual
					+ '&fechaDesde='+ moment(state.fechaDesde).format('YYYY-MM-DD')
					+ '&fechaHasta='+ moment(state.fechaHasta).format('YYYY-MM-DD');

				apiAxios.get('comentarios' + queryString)
					.then(res => {
						if (res.headers.pagination){
							const pagination= JSON.parse(res.headers.pagination)
							var params = {
								paginaActual: pagination.currentPage,
								registrosPorPagina: pagination.itemsPerPage,
								totalRegistros: pagination.totalItems,
								totalPaginas: pagination.totalPages,
							}    
							commit('SET_PAGINATION', params);
						}
	
						commit('SET_COMENTARIOS', res.data)
						resolve();
					})
					.catch(error => reject(helpers.methods.getErrorMessage(error)));
			} else {
				resolve();
			}
		})
	},
	marcarLeido: ( {}, comentarioId) => {
		return new Promise((resolve, reject) => {
			apiAxios.post('comentarios/' + comentarioId + '/leido')
				.then(() => resolve())
				.catch(error => reject(helpers.methods.getErrorMessage(error)));
		})
	},	
};

const getters = {
	getField,
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}