import Vue from 'vue';
import Vuex from 'vuex';

import caracteristicaPropiedad from './modules/caracteristicaPropiedad';
import caracteristicasPropiedades from './modules/caracteristicasPropiedades';
import comentarios from './modules/comentarios';
import propiedad from './modules/propiedad';
import propiedades from './modules/propiedades';
import propiedadImagen from './modules/propiedadImagen';
import spinnerProcesando from './modules/spinnerProcesando';
import tipoPropiedad from  './modules/tipoPropiedad';
import tiposPropiedades from  './modules/tiposPropiedades';
import user from './modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        caracteristicaPropiedad,
        caracteristicasPropiedades,
        comentarios,
        propiedad,
        propiedades,
        propiedadImagen,
        spinnerProcesando,
        tipoPropiedad,
        tiposPropiedades,
        user
   
    }
});