// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// import 'core-js/es6/promise'
// import 'core-js/es6/string'
// import 'core-js/es7/array'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import App from './App'
import router from './router'
import moment from 'moment'
import store from './store/store';

// import LiquorTree from 'liquor-tree';

import inputFormatter from './mixins/inputFormatter';
import helpers from './mixins/helpers';
// import exportXLS from './mixins/export_xls';
import authHeaderInterceptor from './helpers/authHeaderInterceptor';
import { getNumeroALetras } from './mixins/helpers';

/* Modales para msj o msj de confirmación */
import { msjModal, confirmModal } from './mixins/modalDialogs';
import appModalMsj from '../src/views/shared/ModalMsj';
import appModalConfirm from '../src/views/shared/ModalConfirm';

Vue.use(BootstrapVue);
Vue.use(Vuelidate);
// Vue.use(LiquorTree);

Vue.mixin(inputFormatter);
Vue.mixin(helpers);
// Vue.mixin(exportXLS);

/* Modales para msj o msj de confirmación */
Vue.mixin(msjModal);
Vue.mixin(confirmModal);
Vue.component('app-modal-msj', appModalMsj);
Vue.component('app-modal-confirm', appModalConfirm);

Vue.filter('documentoNumero', function (value, documentoTipoId) {
  if (value) {
    let documentoNumero = '';
    switch (documentoTipoId) {
      case 2: /* Cuit */
        if ( value.trim().length == 11 ){
          documentoNumero = value.substring(0, 2) 
            + '-' + value.substring(2, 10)
            + '-' + value.substring(10);
        }
        break;
      case 4: /* EIN */
        if ( value.trim().length == 9 ){
          documentoNumero = value.substring(0, 2) + '-' + value.substring(2);
        }
        break;
    }
    return documentoNumero;
  }
  else
    return '';
})


Vue.filter('cuit', function (cuit) {
  if (cuit) {
    if ( cuit.trim().length == 11 ){
      return cuit.substring(0, 2) 
        + '-' + cuit.substring(2, 10)
        + '-' + cuit.substring(10);
    }
    else
      return cuit;
  }
  else
    return '';
})

Vue.filter('ein', function (documentoNumero) {
  if (documentoNumero) {
    if ( documentoNumero.trim().length == 9 ){
      return documentoNumero.substring(0, 2) + '-' + documentoNumero.substring(2);
    }
    else
      return documentoNumero;
  }
  else
    return '';
})

Vue.filter('fechaABM', function (fecha) {
  return moment(fecha).format('YYYY-MM-DD');
})

Vue.filter('fecha', function (fecha) {
  if (moment(fecha).format('DD/MM/YYYY') == '01/01/1900')
    return '';
  else
    return moment(fecha).format('DD/MM/YYYY');
})

Vue.filter('fechaHora', function (fecha) {
  if (moment.utc(fecha).format('DD/MM/YYYY') == '01/01/1900')
    return '';
  else
    return moment.utc(fecha).format('DD/MM/YYYY HH:mm');
})

Vue.filter('periodo', function (fecha) {
  if (moment(fecha).format('DD/MM/YYYY') == '01/01/1900')
    return '';
  else
    return moment(fecha).format('MM/YYYY');
})

Vue.filter('periodoAnual', function (fecha) {
  if (moment(fecha).format('DD/MM/YYYY') == '01/01/1900')
    return '';
  else
    return moment(fecha).format('YYYY');
})

Vue.filter('moneda', function (value, monedaSimbolo) {
  /*
    var monedaSimbolo = "$"
    if (moneda)
      monedaSimbolo = moneda
    
    var importe = 0
    if (value)
      importe = parseFloat(value)

    return monedaSimbolo + ' ' + importe.toFixed(2)
  */

  let importe = 0;
  if (value) {
    importe = parseFloat(value)
  }

  if (!monedaSimbolo) {
    monedaSimbolo = '$';
  }

  let formatted = importe.toFixed(2);
  
  switch (monedaSimbolo.toLowerCase().trim()) {
    case '$':
      formatted = new Intl.NumberFormat("es-AR", {style: "currency", currency: "ARS"}).format(importe);    
      break;

    case 'u$s':
      formatted = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", currencyDisplay: "code"}).format(importe);    
      // formatted = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", currencyDisplay: "u$s"}).format(importe);    
      break;

    case 'eur':
      formatted = new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR"}).format(importe);    
      // formatted = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", currencyDisplay: "u$s"}).format(importe);    
      break;
  }

  return formatted;
})

Vue.filter('numero', function (value, decimales, show) {
  if (value) {
    var floatNumber = parseFloat(value)
    //return floatNumber.toFixed(decimales)
    return new Intl.NumberFormat("es-AR", {minimumFractionDigits: decimales}).format(floatNumber);
  }
  else {
    if (show) {
      var floatNumber = parseFloat(0)
      //return floatNumber.toFixed(decimales)
      return new Intl.NumberFormat("es-AR", {minimumFractionDigits: decimales}).format(floatNumber);
    }
    else
      return ''
  }
})

Vue.filter('porcentaje', function (value, decimales) {
  let porcentaje = 0
  
  if (value) {
    porcentaje = parseFloat(value / 100);
  }
  if (!decimales) {
    decimales = 2;
  }

  //return porcentaje.toFixed(decimales) + ' %';
  return new Intl.NumberFormat("es-AR", {style: "percent", minimumFractionDigits: decimales})
    .format(porcentaje);
})

Vue.filter('numeroALetras', function (value) {
  return getNumeroALetras(value);
})

/* Interceptor - agrega token al header */
authHeaderInterceptor();

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App
  }
})
