import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
	id: '',
	tipoPropiedadId: 0,
	tipoPropiedad: null,
	tipoPropiedadNombre: '',
	titulo: '',
	descripcion: '',
	direccion: '',
	localidad: '',
	barrio: '',
	locationLat: 0,
	locationLng: 0,
	superficieTotal: 0,
	superficieCubierta: 0,
	antiguedad: 0,
	ambientes: 0,
	dormitorios: 0,
	banios: 0,
	monedaVenta: '',
	precioVenta: 0,
	monedaAlquiler: '',
	precioAlquiler: 0,
	expensas: 0,
	aEstrenar: false,
	destacada: false,
	publicada: false,
	alquilada: false,
	vendida: false,

	caracteristicas: [],
	caracteristicasPropiedades: [],

	comentarios: [],
    comentariosPaginaActual: 1,
    comentariosTotalPaginas: 1,
    comentariosRegistrosPorPagina: 10,
    comentariosTotalRegistros: 0,

	imagenes: [],
    imagenesPaginaActual: 1,
    imagenesTotalPaginas: 1,
    imagenesRegistrosPorPagina: 10,
    imagenesTotalRegistros: 0,
};

const mutations = {
	updateField,

	'INICIALIZAR_PROPIEDAD'(state, propiedadId) {
		state.id = propiedadId;

		state.tipoPropiedadId = '';
		state.tipoPropiedad = null;
		state.tipoPropiedadNombre = null;
		// state.tipoPropiedad = {
		// 	id: '',
		// 	nombre: ''
		// };
		// state.tipoPropiedadNombre = '';
		state.titulo = '';
		state.descripcion = '';
		state.direccion = '';
		state.localidad = '';
		state.barrio = '';
		state.locationLat = 0;
		state.locationLng = 0;
		state.superficieTotal = 0;
		state.superficieCubierta = 0;
		state.antiguedad = 0;
		state.ambientes = 0;
		state.dormitorios = 0;
		state.banios = 0;
		state.monedaVenta = '';
		state.precioVenta = 0;
		state.monedaAlquiler = '';
		state.precioAlquiler = 0;
		state.expensas = 0;
		state.aEstrenar = false;
		state.destacada = false;
		state.publicada = false;
		state.alquilada = false;
		state.vendida = false;
		
		state.comentarios = [];
		state.comentariosTotalRegistros = 0; 

		state.imagenes = [];
		state.imagenesTotalRegistros = 0;

		state.caracteristicas = [];
	},
	'SET_PROPIEDAD'(state, propiedad) {
		state.tipoPropiedadId = propiedad.tipoPropiedad? propiedad.tipoPropiedad._id : 0;
		state.tipoPropiedad = propiedad.tipoPropiedad;
		state.tipoPropiedadNombre = propiedad.tipoPropiedad? propiedad.tipoPropiedad.nombre.trim() : null;
		state.titulo = propiedad.titulo;
		state.descripcion = propiedad.descripcion;
		state.direccion = propiedad.direccion;
		state.localidad = propiedad.localidad;
		state.barrio = propiedad.barrio;
		state.locationLat = propiedad.location.lat;
		state.locationLng = propiedad.location.lng;
		state.superficieTotal = propiedad.superficieTotal;
		state.superficieCubierta = propiedad.superficieCubierta;
		state.antiguedad = propiedad.antiguedad;
		state.ambientes = propiedad.ambientes;
		state.dormitorios = propiedad.dormitorios;
		state.banios = propiedad.banios;
		state.monedaVenta = propiedad.monedaVenta;
		state.precioVenta = propiedad.precioVenta;
		state.monedaAlquiler = propiedad.monedaAlquiler;
		state.precioAlquiler = propiedad.precioAlquiler;
		state.expensas = propiedad.expensas;
		state.aEstrenar = propiedad.aEstrenar;
		state.destacada = propiedad.destacada;
		state.publicada = propiedad.publicada;
		state.alquilada = propiedad.alquilada;
		state.vendida = propiedad.vendida;

		if (propiedad.comentarios) {
			state.comentarios = propiedad.comentarios;
			state.comentariosTotalRegistros = propiedad.comentarios.length;
		}

		if (propiedad.imagenes) {
			state.imagenes = propiedad.imagenes;
			state.imagenesTotalRegistros = propiedad.imagenes.length;
		}

		state.caracteristicas = [];
		state.caracteristicasPropiedades.forEach(caracteristica => {
			let caracteristicaPropiedad = false;

			if (propiedad.caracteristicas) {
				if (propiedad.caracteristicas.length != 0) {
					if (propiedad.caracteristicas.includes(caracteristica.nombre.trim())) {
						caracteristicaPropiedad = true;
					}
				}
			}

			state.caracteristicas.push({
				nombre: caracteristica.nombre.trim(),
				checked: (/true/i).test(caracteristicaPropiedad)
			})
		});
	},
	'SET_TIPO_PROPIEDAD'(state, tipoPropiedad) {
		state.tipoPropiedadId = tipoPropiedad? tipoPropiedad.id : 0;
		state.tipoPropiedad = tipoPropiedad;
		state.tipoPropiedadNombre = tipoPropiedad? tipoPropiedad.nombre.trim() : null;
	},

	'INICIALIZAR_CARACTERISTICAS'(state) {
		state.caracteristicasPropiedades = [];
	},
	'SET_CARACTERISTICAS'(state, caracteristicasPropiedades) {
		state.caracteristicasPropiedades = caracteristicasPropiedades;
	},

	'INICIALIZAR_IMAGENES'(state) {
		state.imagenes = [];
		state.totalRegistros = 0; 
	},
	'SET_IMAGENES'(state, imagenes) {
		if (imagenes) {
			state.imagenes = imagenes;
			state.totalRegistros = imagenes.length;
		}
	},
    'SET_MAIN_IMAGEN' (state, imagenId) {
        let currentMain = state.imagenes
            .find(imagen => imagen.main);
		if (currentMain) {
			currentMain.main = false;
		}

		let imagen = state.imagenes
			.find(imagen => imagen._id == imagenId);
		if (imagen) {
			imagen.main = true;
		}
    },	
    'DELETE_IMAGEN' (state, imagenId) {
        let itemKey = state.imagenes
            .findIndex(imagen => imagen._id == imagenId);

        if (itemKey != -1) {
            state.imagenes.splice(itemKey, 1);
        }
    },	
};

const actions = {
	getPropiedad: ({ commit }, propiedadId) => {
		return new Promise((resolve, reject) => {
			commit('INICIALIZAR_CARACTERISTICAS');
			commit('INICIALIZAR_PROPIEDAD', propiedadId);
			

			let getDataPropiedad = false;

			if (propiedadId) {
				if (propiedadId != '') {
					getDataPropiedad = true;
				}
			}

			apiAxios.get('caracteristicaspropiedades')
				.then(res => {
					commit('SET_CARACTERISTICAS', res.data)
					return;
				})
				.then(() => {
					if (getDataPropiedad) {	
						return apiAxios.get('propiedades/' + propiedadId)
					} else {
						return;
					}
				})
				.then(res => {
					if (getDataPropiedad) {
						commit('SET_PROPIEDAD', res.data)
						resolve();
					}
					resolve();
				})
				.catch(error => reject(helpers.methods.getErrorMessage(error)));
		})
	},
    save ( { state } ) {
        return new Promise((resolve, reject) => {
			let caracteristicas = [];
			if (state.caracteristicas) {
				if (state.caracteristicas.length) {
					state.caracteristicas.forEach(caracteristica => {
						if ((/true/i).test(caracteristica.checked)) {
							caracteristicas.push(caracteristica.nombre.trim());
						}
					});
				}
			}

            let propiedad = {
				tipoPropiedadId: state.tipoPropiedadId,
				titulo: state.titulo.trim(),
				descripcion: state.descripcion.trim(),
				direccion: state.direccion.trim(),
				localidad: state.localidad.trim(),
				barrio: state.barrio.trim(),
				locationLat: parseFloat(state.locationLat),
				locationLng: parseFloat(state.locationLng),
				superficieTotal: parseInt(state.superficieTotal),
				superficieCubierta: parseInt(state.superficieCubierta),
				antiguedad: parseInt(state.antiguedad),
				ambientes: parseInt(state.ambientes),
				dormitorios: parseInt(state.dormitorios),
				banios: parseInt(state.banios),
				monedaVenta: state.monedaVenta.toUpperCase().trim(),
				precioVenta: parseFloat(state.precioVenta),
				monedaAlquiler: state.monedaAlquiler.toUpperCase().trim(),
				precioAlquiler: parseFloat(state.precioAlquiler),
				expensas: parseFloat(state.expensas),
				aEstrenar: state.aEstrenar,
				caracteristicas,
				destacada: state.destacada,
				publicada: state.publicada,
				alquilada: state.alquilada,
				vendida: state.vendida,
            }

            let url = 'propiedades';
            if (state.id != '') {
                // bancoCuenta.rowVersion = state.rowVersion;
                url += '/' + state.id;
            }

            apiAxios.post(url, propiedad)
                .then(() => resolve())
				.catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    setTipoPropiedad: ({commit}, tipoPropiedad) => {
        commit('SET_TIPO_PROPIEDAD', tipoPropiedad);
    },

	// getImagenesPropiedad: ({ commit }, propiedadId) => {
	// 	return new Promise((resolve, reject) => {
	// 		commit('INICIALIZAR_IMAGENES');

	// 		let getDataPropiedad = false;

	// 		if (propiedadId) {
	// 			if (propiedadId != '') {
	// 				getDataPropiedad = true;
	// 			}
	// 		}

	// 		if (getDataPropiedad) {
	// 			apiAxios.get('propiedades/' + propiedadId + '/imagenes')
	// 				.then(res => {
	// 					commit('SET_IMAGENES', res.data)
	// 					resolve();
	// 				})
	// 				.catch(error => reject(error));
	// 				// .catch(error => reject(helpers.methods.getErrorMessage(error)));
	// 		} else {
	// 			resolve();
	// 		}
	// 	})
	// },
    addImagen ( { commit }, params ) {
        return new Promise((resolve, reject) => {
            let error;
            let fileName = params.file.name;
            let fileExtension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
            let fileSize = parseFloat(params.file.size / 1048576);

            if (fileExtension != 'jpg' 
                & fileExtension != 'png'
                & fileExtension != 'jpeg') {
                    error = 'El tipo de archivo seleccionado no es válido. Sólo se permiten imágenes de tipo jpg, png o jpeg.';
            }

            if (fileSize >= 0.3 ) {
                error = 'El archivo seleccionado tiene un peso mayor al permitido. Sólo se permiten imágenes de hasta 300KB.';
            }

            if (error) {
                reject(error);
            } else {
				let formData = new FormData();
                formData.append('file', params.file);
				formData.append('main', params.main);

                let options = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }				

				apiAxios.post('propiedades/' + params.propiedadId + '/imagenes', formData, options)
					.then(() => {
						return apiAxios.get('propiedades/' + params.propiedadId + '/imagenes');
					})
					.then(res => {
						commit('SET_IMAGENES', res.data);
						resolve();
					})
					.catch(error => reject(helpers.methods.getErrorMessage(error)));
										
			}
        })
    },	
    deleteImagen ( { commit }, params ) {
        return new Promise((resolve, reject) => {
            apiAxios.post('propiedades/' + params.propiedadId + '/imagenes/' + params.imagenId + '/delete')
                .then(() => {
					commit('DELETE_IMAGEN', params.imagenId);
					// resolve();
					return;
				})
				.then(() => {
					return apiAxios.get('propiedades/' + params.propiedadId + '/imagenes/main');
				})
                .then(res => {
					commit('SET_MAIN_IMAGEN', res.data._id);
					resolve();
				})
				.catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    setMainImagen ( { commit }, params ) {
        return new Promise((resolve, reject) => {
			apiAxios.post('propiedades/' + params.propiedadId + '/imagenes/' + params.imagenId + '/main')
			.then(() => {
				commit('SET_MAIN_IMAGEN', params.imagenId);
				resolve();
			})
			.catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },

};

const getters = {
	getField,
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}