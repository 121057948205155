import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
};

const mutations = {
	updateField,
};

const actions = {
    setMain ( {}, params ) {
        return new Promise((resolve, reject) => {
			apiAxios.post('propiedades/' + params.propiedadId + '/imagenes/' + params.imagenId + '/main')
                .then(() => resolve())
				.catch( error => reject(error));
        })
    },
    save ( {}, params ) {
        return new Promise((resolve, reject) => {
            let error;
            let fileName = params.file.name;
            let fileExtension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
            let fileSize = parseFloat(params.file.size / 1048576);

            if (fileExtension != 'jpg' 
                & fileExtension != 'png'
                & fileExtension != 'jepg') {
                    error = 'El tipo de archivo seleccionado no es válido. Sólo se permiten imágenes de tipo jpg, png o jpeg.';
            }

            if (fileSize >= 0.3 ) {
                error = 'El archivo seleccionado tiene un peso mayor al permitido. Sólo se permiten imágenes de hasta 300KB.';
            }

            if (error) {
                reject(error);
            } else {
				let formData = new FormData();
                formData.append('file', params.file);
				formData.append('main', params.main);

                let options = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }				

				apiAxios.post('propiedades/' + params.propiedadId + '/imagenes', formData, options)
					.then(() => resolve())
					.catch( error => reject(error));
			}
        })
    },
};

const getters = {
	getField,
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}