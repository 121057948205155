import moment from 'moment';

export default {
    methods: {
      procesando () {
        let height = 0;
        let width = 0;
        if (document.getElementsByClassName("app-body")) {
          if (document.getElementsByClassName("app-body")[0]) {
            height = document.getElementsByClassName("app-body")[0].clientHeight
              + document.getElementsByClassName("app-body")[0].offsetTop;
            width = document.getElementsByClassName("app-body")[0].clientWidth;
          }
        }
        let params = {
          height,
          width
        }

        this.$store.dispatch('spinnerProcesando/procesando', params);
      },
      showSpinner () {
        let height = 0;
        let width = 0;
        if (document.getElementsByClassName("app-body")) {
          if (document.getElementsByClassName("app-body")[0]) {
            height = document.getElementsByClassName("app-body")[0].clientHeight
              + document.getElementsByClassName("app-body")[0].offsetTop;
            width = document.getElementsByClassName("app-body")[0].clientWidth;
          }
        }
        let params = {
          height,
          width
        }
        this.$store.dispatch('spinnerProcesando/show', params);
      },
      hideSpinner () {
        this.$store.dispatch('spinnerProcesando/hide');
      },
      getSelected (list, nombre) {
          var selected = {};
          if (nombre) {
            for ( let key in list ){
              const element = list[key];
              if (element.nombre.trim() == nombre.trim() ){
                selected = element;
              }
            }
          }
          return selected;
      },
      formattedData (item) {
        var value = item.valor;
        switch (item.formato.trim()) {
          case "cuit":
            value = this.$options.filters.cuit(item.valor);
            break;
          case "ein":
            value = this.$options.filters.ein(item.valor);
            break;
          case "porcentaje":
            value = this.$options.filters.porcentaje(item.valor);
            break;
          case "importe":
            value = this.$options.filters.numero(item.valor, 2, true);
            break;
          case "moneda":
            value = this.$options.filters.moneda(item.valor);
            break;
          case "fecha":
            value = this.$options.filters.fecha(item.valor);
            break;
          case "fechaHora":
            value = this.$options.filters.fechaHora(item.valor);
            break;
          case "periodo":
            value = this.$options.filters.periodo(item.valor);
            break;
          case "periodoAnual":
            value = this.$options.filters.periodoAnual(item.valor);
            break;
          }
        return value;
      },
      formattedCuit(cuit) {
        if (cuit) {
          if ( cuit.trim().length == 11 ){
            return cuit.substring(0, 2) 
              + '-' + cuit.substring(2, 10)
              + '-' + cuit.substring(10);
          }
          else
            return cuit;
        }
        else
          return ''
      },
      formattedEIN(documentoNumero) {
        if (documentoNumero) {
          if ( documentoNumero.trim().length == 9 ){
            return documentoNumero.substring(0, 2) + '-' + documentoNumero.substring(2);
          }
          else
            return documentoNumero;
        }
        else
          return '';
      },
      camelize(str) {
        return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
          if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
            return index == 0 ? match.toUpperCase() : match.toLowerCase();
        });
      },
      isEmpty(obj) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
      },      
      getMesNombre(mes) {
        let mesNombre = '';
        switch (parseInt(mes)) {
            case 1:
                mesNombre = 'Enero';
                break;
            case 2:
                mesNombre = 'Febrero';
                break;
            case 3:
                mesNombre = 'Marzo';
                break;
            case 4:
                mesNombre = 'Abril';
                break;
            case 5:
                mesNombre = 'Mayo';
                break;
            case 6:
                mesNombre = 'Junio';
                break;
            case 7:
                mesNombre = 'Julio';
                break;
            case 8:
                mesNombre = 'Agosto';
                break;
            case 9:
                mesNombre = 'Septiembre';
                break;
            case 10:
                mesNombre = 'Octubre';
                break;
            case 11:
                mesNombre = 'Noviembre';
                break;
            case 12:
                mesNombre = 'Diciembre';
                break;
            default:
                mesNombre = null;
                break;
        }
        return mesNombre;
      }, 
      getMesNumero(mes) {
        let mesNumero = 0;
    
        if (mes != null) {
            switch (mes.toLowerCase().trim()) {
                case 'enero':
                    mesNumero = 1;
                    break;
                case 'febrero':
                    mesNumero = 2;
                    break;
                case 'marzo':
                    mesNumero = 3;
                    break;
                case 'abril':
                    mesNumero = 4;
                    break;
                case 'mayo':
                    mesNumero = 5;
                    break;
                case 'junio':
                    mesNumero = 6;
                    break;
                case 'julio':
                    mesNumero = 7;
                    break;
                case 'agosto':
                    mesNumero = 8;
                    break;
                case 'septiembre':
                    mesNumero = 9;
                    break;
                case 'octubre':
                    mesNumero = 10;
                    break;
                case 'noviembre':
                    mesNumero = 11;
                    break;
                case 'diciembre':
                    mesNumero = 12;
                    break;
                default:
                    mesNumero = 0;
                    break;
            }
        }
        return mesNumero;
      },
      getMesNumeroToString(mes) {
        let mesNumero = 0;
    
        if (mes != null) {
            switch (mes.toLowerCase().trim()) {
                case 'enero':
                    mesNumero = '01';
                    break;
                case 'febrero':
                    mesNumero = '02';
                    break;
                case 'marzo':
                    mesNumero = '03';
                    break;
                case 'abril':
                    mesNumero = '04';
                    break;
                case 'mayo':
                    mesNumero = '05';
                    break;
                case 'junio':
                    mesNumero = '06';
                    break;
                case 'julio':
                    mesNumero = '07';
                    break;
                case 'agosto':
                    mesNumero = '08';
                    break;
                case 'septiembre':
                    mesNumero = '09';
                    break;
                case 'octubre':
                    mesNumero = '10';
                    break;
                case 'noviembre':
                    mesNumero = '11';
                    break;
                case 'diciembre':
                    mesNumero = '12';
                    break;
                default:
                    mesNumero = '00';
                    break;
            }
        }
        return mesNumero;
      },
      isValidDate(dia, mes, anio) {
        let isValid = Boolean(true);
  
        if (dia != 0) {
          if (mes != 0) {
            if (anio != 0) {
              let fechaNacimiento = anio.toString().padStart(4, '0')
                + "-" + mes.toString().padStart(2, '0')
                + "-" + dia.toString().padStart(2, '0');
  
              let date = moment(fechaNacimiento);
              isValid = date.isValid(date);
  
            } else {
              switch (mes) {
                case 2:
                  if (dia > 28) {
                    isValid = Boolean(false);
                  }
                  break;
                case 4:
                case 6:
                case 9:
                case 11:  
                  if (dia == 31) {
                    isValid = Boolean(false);
                  }
                  break;
              }
            }
          }
        }
        return isValid;
      },
      getDias() {
        return [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
      },
      getMeses() {
        return [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
      },
      getAnios() {
        let anios = [];
        let anio = 1960;
        let date = new Date();
        let current = parseInt(date.getFullYear());
        
        while (anio <= current) {
          anios.push (anio);
          anio ++;
        }
        return anios;
        /*
        return [ 1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977, 1978, 1979, 
          1980, 1981, 1982, 1973, 1974, 1975, 1976, 1977, 1978, 1979, 
          ];
        */
      },
      getAniosPeriodo() {
        let anios = [];
        let date = new Date();
        let current = parseInt(date.getFullYear());
        
        anios.push(current + 1);
        anios.push(current);
        anios.push(current - 1);
        anios.push(current - 2);

        return anios;
      },
      getTiposEnvios() {
        let tiposEnvios = [
          {
            id: 1,
            nombre: 'PAQUETES'
          },
          {
            id: 2,
            nombre: 'BULTOS'
          },
          {
            id: 3,
            nombre: 'PALLETS'
          },
        ];

        return tiposEnvios;
      },
      getTiposEnviosList() {
        let tiposEnvios = ['PAQUETES', 'BULTOS', 'PALLETS'];

        return tiposEnvios;
      },
      getErrorMessage(error) {
        let errorMessage = '';
        if (error.response) {
          if (error.response.status == 500) {
              if (error.response.data) {
                switch (errorMessage = error.response.data.trim().toLowerCase()) {
                  case 'unable to connect to any of the specified mysql hosts.':
                    errorMessage = 'Se produjo un error al intentar conectar con la base de datos.'
                    break;
                
                  default:
                    errorMessage = error.response.data;
                    break;
                }
              }
          } else if (error.response.status == 400) {
            if (error.response.data) {
              errorMessage = error.response.data;

              if (error.response.data.hasOwnProperty('Message')) {
                if (error.response.data.Message == 'ModelStateInvalid') {
                  if (error.response.data.Data.validations) {
                    errorMessage = '';

                    for (const validationItem in error.response.data.Data.validations) {
                      let validation = error.response.data.Data.validations[validationItem];

                      if (validation.errors) {
                          validation.errors.forEach(error => {
                            errorMessage += '\r\n' + error.errorMessage.trim();
                          });
                      }
                    }
                  } 
                }
              }
            } else {
              errorMessage = error.message;
            }
          }
        }  
        if (errorMessage == '') {
          if (error.message) {
            switch (error.message.trim().toLowerCase()) {
              case 'network error':
                errorMessage = 'Se produjo un error al intentar conectar al servidor de la aplicación.'
                break;
            
              default:
                errorMessage = error.message;
                break;
            }
          }
        }
        return errorMessage;
      },
      displayErrorMonto( fieldError, fieldErrorDecimal, fieldErrorMinValue) {
        let errorMsj = '';
        if (fieldError) {
          if (fieldErrorDecimal) {
            errorMsj = 'El monto ingresado debe ser un valor numérico';
          } else if (fieldErrorMinValue) {
            errorMsj = 'El monto ingresado no es válido';
          }
        }
        //console.log('DisplayErrorMonto', fieldError, fieldErrorDecimal, fieldErrorMinValue, errorMsj);
        return errorMsj;
      },
      roundToTwo(num) {    
        return +(Math.round(num + "e+2")  + "e-2");
      } 
    },
    splitLines(text, font, maxTextWidth) {
      // Split text into words by spaces
      let words = text.split(' ');
      let lastWord = words[words.length - 1];
      let lineWidth = 0;
      let wordWidth = 0;
      let thisLine = '';
      let allLines = new Array();
    
      // For every element in the array of words
      for (var i = 0; i < words.length; i++) {
          var word = words[i];
          // Add current word to current line
          thisLine = thisLine.concat(word + ' ');
          // Get width of the entire current line
          lineWidth = font.getWidth(thisLine);
          // If word is not the last element in the array
          if (word !== lastWord) {
              // Find out what the next upcoming word is
              var nextWord = words[i + 1];
    
              // Check if the current line + the next word would go over width limit
              if (lineWidth + font.getWidth(nextWord) >= maxTextWidth) {
                  // If so, add the current line to the allLines array
                  // without adding the next word
                  addToAllLines(thisLine);
              } 
    
              // '~' indicates inserting a blank line, if required
              else if (word === '~') {
                  addToAllLines(' ');
              }
    
              // If the next word is a line break, end line now
              else if (nextWord === '~') {
                  addToAllLines(thisLine);
              }
          }
    
          // If this IS the last word in the array
          else {
              // Add this entire line to the array and return allLines
              addToAllLines(thisLine);
              return allLines;
          }
      }
    
      // Function that adds text to the array of all lines
      function addToAllLines(text) {
          allLines.push(text);
          thisLine = '';
          lineWidth = 0;
      }		
    }
};

export function getNumeroALetras(numero) {
  let numeroALetras = '';

  let pesos = parseInt(numero).toString();
  let centavos = numero.toFixed(2).substr(-2);

  /* Para trabajar con decimales */
  //numero = numero * 100.00;
  //console.log(numero);

  /* 999.999.999.999,99 */
  //let numeroEnLetras = numero.toString().trim();
  let numeroEnLetras = pesos.padStart(9, '0');
  //console.log('numeroEnLetras', numeroEnLetras);

  let millones = numeroEnLetras.substr(0, 3);
  let miles = numeroEnLetras.substr(3, 3);
  let cientos = numeroEnLetras.substr(6, 3);

  if (parseInt(millones) != 0) {
    numeroALetras += getLetrasDigitos(millones, false);
    if (parseInt(millones) == 1) {
      numeroALetras = numeroALetras.trim() + ' millon ';
    } else {
      numeroALetras = numeroALetras.trim() + ' millones ';
    }
  }
  if (parseInt(miles) != 0) {
    numeroALetras += getLetrasDigitos(miles, false).trim() + ' mil ';
  }
  if (parseInt(cientos) != 0) {
    numeroALetras += getLetrasDigitos(cientos, true);
  }

  numeroALetras = numeroALetras.trim() + ' con ' + centavos.trim() + '/100 centavos.';

  return numeroALetras;
}

function getLetrasDigitos(cadenaDigitos, cientos) {
  let numeroALetras = '';

  let digitos = cadenaDigitos.split('');
  
  if (digitos) {
    let numeroDecenas = parseInt(cadenaDigitos.substr(1));
    
    if (numeroDecenas == 0 && parseInt(digitos[0]) == 1) {
      numeroALetras += 'cien ';
    } else {
      numeroALetras += getCentenas(digitos[0]) + ' ';
    }
    
    if ( numeroDecenas <= 10 ) {
      numeroALetras += getDecenas(digitos[1]) + ' ';
      numeroALetras += getUnidades(digitos[2], cientos) + ' ';
    } else if ( numeroDecenas < 20 ) {
      switch (numeroDecenas) {
        case 11:
          numeroALetras += 'once';
          break;
        case 12:
          numeroALetras += 'doce';
          break;
        case 13:
          numeroALetras += 'trece';
          break;
        case 14:
          numeroALetras += 'catorce';
          break;
        case 15:
          numeroALetras += 'catorce';
          break;
        case 16:
        case 17:
        case 18:
        case 19:
          numeroALetras += 'dieci' + getUnidades(digitos[2]);
          break;
      }
    } else if ( numeroDecenas == 20 ) {
      numeroALetras += 'veinte ';
    } else if ( numeroDecenas < 30 ) {
      numeroALetras += 'veinti' + getUnidades(digitos[2], cientos) + ' ';
    } else {
      numeroALetras += getDecenas(digitos[1]);
      if (parseInt(digitos[2]) != 0) {
        numeroALetras += ' y ';
      }
      numeroALetras += getUnidades(digitos[2], cientos) + ' ';
    }
  }
  return numeroALetras;
}

function getCentenas(numero) {
  let numeroALetras = '';
  switch (parseInt(numero)) {
    case 1:
      numeroALetras = 'ciento';
      break;
    case 2:
      numeroALetras = 'doscientos';
      break;
    case 3:
      numeroALetras = 'trescientos';
      break;
    case 4:
      numeroALetras = 'cuatrocientos';
      break;
    case 5:
      numeroALetras = 'quinientos';
      break;
    case 6:
      numeroALetras = 'seiscientos';
      break;
    case 7:
      numeroALetras = 'setecientos';
      break;
    case 8:
      numeroALetras = 'ochocientos';
      break;
    case 9:
      numeroALetras = 'novecientos';
      break;
  }
  return numeroALetras;
};

function getDecenas(numero) {
  let numeroALetras = '';
  switch (parseInt(numero)) {
    case 1:
      numeroALetras = 'diez';
      break;
    case 2:
      numeroALetras = 'veinte';
      break;
    case 3:
      numeroALetras = 'treinta';
      break;
    case 4:
      numeroALetras = 'cuarenta';
      break;
    case 5:
      numeroALetras = 'cincuenta';
      break;
    case 6:
      numeroALetras = 'sesenta';
      break;
    case 7:
      numeroALetras = 'setenta';
      break;
    case 8:
      numeroALetras = 'ochenta';
      break;
    case 9:
      numeroALetras = 'noventa';
      break;
  }
  return numeroALetras;
};

function getUnidades(numero, cientos) {
  let numeroALetras = '';
  switch (parseInt(numero)) {
    case 1:
      if (cientos) {
        numeroALetras = 'uno';
      } else {
        numeroALetras = 'un';
      }
      break;
    case 2:
      numeroALetras = 'dos';
      break;
    case 3:
      numeroALetras = 'tres';
      break;
    case 4:
      numeroALetras = 'cuatro';
      break;
    case 5:
      numeroALetras = 'cinco';
      break;
    case 6:
      numeroALetras = 'seis';
      break;
    case 7:
      numeroALetras = 'siete';
      break;
    case 8:
      numeroALetras = 'ocho';
      break;
    case 9:
      numeroALetras = 'nueve';
      break;
  }
  return numeroALetras;
};

