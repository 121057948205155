<template>
  <b-modal 
      title="Proprietas"
      v-model="isVisible" 
      :header-bg-variant="variantDialog"
      ok-only 
      ok-variant="primary footer-button"
      @ok="okAction" 
      @hidden="hiddenAction">
    {{ mensajeDialog }}
  </b-modal>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    mensaje: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      default: 'light'
    }
  },
  computed: {
    isVisible: {
      get: function () {
        return this.visible;
      },
      set: function (newValue) {}
    },    
    mensajeDialog: {
      get: function () {
        return this.mensaje;
      },
      set: function (newValue) {}
    },    
    variantDialog: {
      get: function () {
        return this.variant;
      },
      set: function (newValue) {}
    },    
  },
  methods: {
    okAction() {
      this.$emit('okAction');
    },
    hiddenAction() {
      this.$emit('hiddenAction');
    }
  }
}
</script>

<style>
</style>
