import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
// import helpers from '../../mixins/helpers';

const state = {
	caracteristicasPropiedades: [],
    paginaActual: 1,
    totalPaginas: 1,
    registrosPorPagina: 10,
    totalRegistros: 0,

	list: [],
	selectList: [],
};

const mutations = {
	updateField,

	'INICIALIZAR_CARACTERISTICAS_PROPIEDADES'(state) {
		state.caracteristicasPropiedades = [];
		state.totalRegistros = 0; 
	},
	'SET_CARACTERISTICAS_PROPIEDADES'(state, caracteristicasPropiedades) {
		state.caracteristicasPropiedades = caracteristicasPropiedades;
		state.totalRegistros = caracteristicasPropiedades.length; 
	},

	'INICIALIZAR_LIST'(state) {
		state.list = [];
		state.selectList = [];
	},
	'SET_LIST'(state, list) {
		state.list = list;
		state.selectList = list.map(caracteristicaPropiedad => caracteristicaPropiedad.nombre.trim());
	},
};

const actions = {
	getCaracteristicasPropiedades: ({ commit }) => {
		return new Promise((resolve, reject) => {
			let getCaracteristicasPropiedades = true;

			commit('INICIALIZAR_CARACTERISTICAS_PROPIEDADES');
			// if (state.propiedades) {
			//     if (state.propiedades.length != 0) {
			//         getPropiedades = true;
			//     }
			// }

			if (getCaracteristicasPropiedades) {
				let queryString = '';

				apiAxios.get('caracteristicaspropiedades' + queryString)
					.then(res => {
						commit('SET_CARACTERISTICAS_PROPIEDADES', res.data)

						resolve();
					})
					.catch(error => reject(error));
					// .catch(error => reject(helpers.methods.getErrorMessage(error)));
			} else {
				resolve();
			}
		})
	},
	getList: ({ commit }) => {
		return new Promise((resolve, reject) => {
			commit('INICIALIZAR_LIST');
			
			apiAxios.get('caracteristicaspropiedades/list')
				.then(res => {
					commit('SET_LIST', res.data)
					resolve();
				})
				.catch(error => reject(error));
				// .catch(error => reject(helpers.methods.getErrorMessage(error)));
		})
	},
};

const getters = {
	getField,

	list(state) {
		return state.list;
	},
	selectList(state) {
		return state.selectList;
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}