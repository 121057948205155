export default {
    methods: {
        inputFormatCadena(value, event) {
            return value.toUpperCase()
        },
        inputFormatNumero(value, event) {
            var number = parseInt(value)
            if (!number)
              number = 0
            return number
        },        
        inputFormatImporte(value, event) {
            var number = parseFloat(value)
            if (!number)
              number = 0
            return number
        },
        inputFormatPorcentaje(value, event) {
            var number = parseFloat(value)
            if (!number)
              number = 0
      
            if (number > 100 || number < 0)
              number = 0
      
            return number
          },
        inputFormatFormulario(value, event) {
            var formulario = ''
      
            if (value.toLowerCase() == 'a' 
                || value.toLowerCase() == 'b'
                || value.toLowerCase() == 'c'
                || value.toLowerCase() == 'p'
                || value.toLowerCase() == 'x')
              formulario = value.toUpperCase()
      
            return formulario
        },
    },
};