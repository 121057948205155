import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store'

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')

// Views
/*
const Dashboard = () => import('@/views/Dashboard')
*/

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
// const Login = () => import('@/views/pages/Login')
const Login = () => import('@/views/usuarios/Login')
const LoginEmpresa = () => import('@/views/usuarios/Empresa')
const Register = () => import('@/views/pages/Register')

const Dashboard = () => import('@/views/Dashboard')
const Inicio = () => import('@/views/Inicio')
const CaracteristicasPropiedades = () => import('@/views/caracteristicasPropiedades/List')
const CaracteristicaPropiedad = () => import('@/views/caracteristicasPropiedades/ABM')
const Comentarios = () => import('@/views/comentarios/List')
const Propiedades = () => import('@/views/propiedades/List')
const Propiedad = () => import('@/views/propiedades/ABM')
const PropiedadDatos = () => import('@/views/propiedades/Data')
const PropiedadImagen = () => import('@/views/propiedades/Imagen')
const PropiedadImagenes = () => import('@/views/propiedades/Imagenes')
const TiposPropiedades = () => import('@/views/tiposPropiedades/List')
const TipoPropiedad = () => import('@/views/tiposPropiedades/ABM')


Vue.use(Router)

function requireUser (to, from, next) {
  if ( !store.getters['user/loggedIn'] ) {
    // next ('/pages/login');
    store.dispatch('user/tryLogin')
      .then(() => {
        /* Logueamos a partir de las credenciales del localStorage */
        next ();  
      })
      .catch(() => {
        /* No hay un usuario logueado, redirigimos al login */
        next ('/login');
      });        
  } else {
    next ();
  }
}

function requireAuth (to, from, next) {
  if ( !store.getters['user/loggedIn'] ) {
    // next ('/pages/login');
    store.dispatch('user/tryLogin')
      .then(() => {
        /* Logueamos a partir de las credenciales del localStorage */
        next ();  
      })
      .catch(() => {
        /* No hay un usuario logueado, redirigimos al login */
        next ('/login');
      });        
  } else {
    /* Verificamos si hay una empresa seleccionada */
    if (store.getters['user/empresaSeleccionada']) {
      next ();
    } else {
      next('/empresa');
    }
  }
}

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/inicio',
      name: 'Home',
      meta: { label: 'Proprietas'},
      component: DefaultContainer,
      children: [
        {
          path: 'inicio',
          // path: '',
          name: 'Inicio',
          meta: { label: 'Inicio'},
          component: Inicio,
          beforeEnter: requireAuth
        },

        {
          path: 'propiedades',
          meta: { label: 'Propiedades' },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              meta: { label: 'Listado de Propiedades' },
              name: 'Propiedades',
              component: Propiedades,
              beforeEnter: requireAuth
            },
            {
              path: ':id/abm',
              meta: { label: 'Informacion de la Propiedad' },
              name: 'Propiedad',
              component: Propiedad,
              beforeEnter: requireAuth
            },
            {
              path: ':id',
              meta: { label: 'Informacion de la Propiedad' },
              name: 'PropiedadDatos',
              component: PropiedadDatos,
              beforeEnter: requireAuth
            },
            {
              path: ':id/imagenes',
              meta: { label: 'Imagenes de la Propiedad' },
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  meta: { label: 'Listado de Imagenes de la Propiedad' },
                  name: 'PropiedadImagenes',
                  component: PropiedadImagenes,
                  beforeEnter: requireAuth,
                },
                {
                  path: ':imagenId',
                  meta: { label: 'Información de la Imagen' },
                  name: 'PropiedadImagen',
                  component: PropiedadImagen,
                  beforeEnter: requireAuth
                }
              ]
            },
          ]
        },        

        {
          path: 'comentarios',
          meta: { label: 'Comentarios' },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              meta: { label: 'Listado de Comentarios' },
              name: 'Comentarios',
              component: Comentarios,
              beforeEnter: requireAuth
            },
          ]
        },        

        {
          path: 'tipos-propiedades',
          meta: { label: 'Tipos de Propiedades' },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              meta: { label: 'Listado de Tipos de Propiedades' },
              name: 'TiposPropiedades',
              component: TiposPropiedades,
              beforeEnter: requireAuth
            },
            {
              path: ':id',
              meta: { label: 'Informacion del Tipo de Propiedad' },
              name: 'TipoPropiedad',
              component: TipoPropiedad,
              beforeEnter: requireAuth
            }
          ]
        },        
        {
          path: 'caracteristicas-propiedades',
          meta: { label: 'Caracteristicas de Propiedades' },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              meta: { label: 'Listado de Caracteristicas' },
              name: 'CaracteristicasPropiedades',
              component: CaracteristicasPropiedades,
              beforeEnter: requireAuth
            },
            {
              path: ':id',
              meta: { label: 'Informacion de la Característica' },
              name: 'CaracteristicaPropiedad',
              component: CaracteristicaPropiedad,
              beforeEnter: requireAuth
            }
          ]
        },
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/empresa',
      name: 'LoginEmpresa',
      component: LoginEmpresa,
      beforeEnter: requireUser
    },

    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
      ]
    },

  ]
})
