import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
	propiedades: [],

	/* Opciones de filtro y paginacion */
    paginaActual: 1,
    registrosPorPagina: 10,
    totalRegistros: 0,
    totalPaginas: 0,

	titulo: '',
	direccion: '',
	tipoPropiedad: null,
	publicadas: false,
	destacadas: false
};

const mutations = {
	updateField,

	'INICIALIZAR_PROPIEDADES'(state) {
		state.propiedades = [];
	},
    'SET_PAGINATION'(state, params) {
        state.paginaActual = params.paginaActual;
        state.registrosPorPagina = params.registrosPorPagina;
        state.totalRegistros = params.totalRegistros;
        state.totalPaginas = params.totalPaginas;
    },
	'SET_PROPIEDADES'(state, propiedades) {
		state.propiedades = propiedades;
	},
};

const actions = {
	getPropiedades: ({ commit }) => {
		return new Promise((resolve, reject) => {
			let getPropiedades = true;

			commit('INICIALIZAR_PROPIEDADES');

			// if (state.propiedades) {
			//     if (state.propiedades.length != 0) {
			//         getPropiedades = true;
			//     }
			// }

			if (getPropiedades) {
				let queryString = '?pageSize=' + state.registrosPorPagina
                	+ '&pageNumber=' + state.paginaActual;
				
				if (state.titulo) {
					if (state.titulo != '') {
						queryString += '&titulo=' + state.titulo.trim();
					}
				}
				if (state.direccion) {
					if (state.direccion != '') {
						queryString += '&direccion=' + state.direccion.trim();
					}
				}
				if (state.tipoPropiedad) {
					if (state.tipoPropiedad.id != '') {
						if (state.tipoPropiedad.id != '') {
							queryString += '&tipoPropiedadId=' + state.tipoPropiedad.id.trim();
						}
					}
				}
				if ((/true/i).test(state.publicadas)) {
					queryString += '&publicadas=true';
				}
				if ((/true/i).test(state.destacadas)) {
					queryString += '&destacadas=true';
				}

				apiAxios.get('propiedades' + queryString)
					.then(res => {
						if (res.headers.pagination){
							const pagination= JSON.parse(res.headers.pagination)
							var params = {
								paginaActual: pagination.currentPage,
								registrosPorPagina: pagination.itemsPerPage,
								totalRegistros: pagination.totalItems,
								totalPaginas: pagination.totalPages,
							}    
							commit('SET_PAGINATION', params);
						}

						commit('SET_PROPIEDADES', res.data)
						resolve();
					})
					.catch(error => reject(helpers.methods.getErrorMessage(error)));
			} else {
				resolve();
			}
		})
	},
};

const getters = {
	getField,
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}