import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
// import helpers from '../../mixins/helpers';

const state = {
	tiposPropiedades: [],
    paginaActual: 1,
    totalPaginas: 1,
    registrosPorPagina: 10,
    totalRegistros: 0,

	list: [],
	selectList: [],
};

const mutations = {
	updateField,

	'INICIALIZAR_TIPOS_PROPIEDADES'(state) {
		state.tiposPropiedades = [];
		state.totalRegistros = 0; 
	},
	'SET_TIPOS_PROPIEDADES'(state, tiposPropiedades) {
		state.tiposPropiedades = tiposPropiedades;
		state.totalRegistros = tiposPropiedades.length; 
	},

	'INICIALIZAR_LIST'(state) {
		state.list = [];
		state.selectList = [];
	},
	'SET_LIST'(state, list) {
		state.list = list;
		state.selectList = list.map(tipoPropiedad => tipoPropiedad.nombre.trim());
	},
};

const actions = {
	getTiposPropiedades: ({ commit }) => {
		return new Promise((resolve, reject) => {
			let getTiposPropiedades = true;

			commit('INICIALIZAR_TIPOS_PROPIEDADES');
			// if (state.propiedades) {
			//     if (state.propiedades.length != 0) {
			//         getPropiedades = true;
			//     }
			// }

			if (getTiposPropiedades) {
				let queryString = '';

				apiAxios.get('tipospropiedades' + queryString)
					.then(res => {
						commit('SET_TIPOS_PROPIEDADES', res.data)

						resolve();
					})
					.catch(error => reject(error));
					// .catch(error => reject(helpers.methods.getErrorMessage(error)));
			} else {
				resolve();
			}
		})
	},
	getList: ({ commit }) => {
		return new Promise((resolve, reject) => {
			commit('INICIALIZAR_LIST');
			
			apiAxios.get('tipospropiedades/list')
				.then(res => {
					commit('SET_LIST', res.data)
					resolve();
				})
				.catch(error => reject(error));
				// .catch(error => reject(helpers.methods.getErrorMessage(error)));
		})
	},
};

const getters = {
	getField,

	list(state) {
		return state.list;
	},
	selectList(state) {
		return state.selectList;
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}